import { useState } from "react";
export default function Menu(props) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { currentPath, navItems } = props;

  return (
    <div className="relative">
      <button onClick={toggleMenu} className="flex items-center">
        {showMenu ? props.closeIcon : props.menuIcon}
      </button>
      {showMenu && (
        <div className="flex flex-col bg-primary-white fixed top-16 left-0 w-full h-full shadow-md z-50 gap-6 ">
          <nav className="flex flex-col gap-6 p-8 pb-0">
            {
              navItems.map((item) => (
                <a
                  href={item.path}
                  className={`nav-item text-primary-black font-medium text-sm flex flex-row items-center justify-between pb-4 ${decodeURI(currentPath) === item.path ? "border-b-2 border-primary-red" : ""}`}
                >
                  {item.label}
                  {props.icon}
                </a>
              ))
            }
          </nav>
          {props.languageSwitch}
        </div>
      )}
    </div>
  );
}
